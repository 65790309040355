import { Container, Flex, Grid, Text } from '@actovos-consulting-group/ui-core';
import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import Toast from '../svgs/toast';

const StyledFooter = styled.footer`
  background: ${p => p.theme.colors.primary};
  color: white;
  padding: 30px;
  margin-top: 50px;
  flex-shrink: 0;
`;

const FooterLink = styled(Link)`
  color: white;
`;

const ColumnLink = styled(FooterLink)`
  text-decoration: none;
  font-size: 1rem;
  margin-top: 10px;
  display: block;
`;

const ColumnHeader = styled.div`
  font-weight: 600;
  font-size: 1.5rem;
`;

const FooterRow = styled(Grid.Row)`
  margin-left: 40px;

  @media (max-width: ${p => p.theme.breakpoints.md}) {
    margin-left: 25vw;
  }
  @media (max-width: ${p => p.theme.breakpoints.sm}) {
    margin-left: 0;
    text-align: center;
  }
`;

const Footer = () => {
  return (
    <StyledFooter>
      <Container maxWidth={1000}>
        <FooterRow>
          <Grid.Column size={{ _: 12, sm: 6, md: 4 }}>
            <ColumnHeader>Products</ColumnHeader>
            <ColumnLink to="/products">All Products</ColumnLink>
          </Grid.Column>
          <Grid.Column size={{ _: 12, sm: 6, md: 4 }}>
            <ColumnHeader>Company</ColumnHeader>
            <div>
              <ColumnLink to="/faqs">Faq&lsquo;s</ColumnLink>
            </div>
          </Grid.Column>
          <Grid.Column size={{ _: 12, sm: 6, md: 4 }}>
            <ColumnHeader>Legal</ColumnHeader>
            <div>
              <ColumnLink to="/terms-of-service">Terms of Service</ColumnLink>
              <ColumnLink to="/terms-of-sale">Terms of Sale</ColumnLink>
            </div>
          </Grid.Column>
        </FooterRow>
        <Flex flexDirection="column" alignItems="center" mt={50}>
          <Link to="/">
            <Toast width={50} color="white" />
          </Link>
          <Text textAlign="center" as="p">
            Copyright © 2021 RoastOrToast All Rights Reserved.
          </Text>
        </Flex>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
