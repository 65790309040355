import { Block } from '@actovos-consulting-group/ui-core';
import React from 'react';
import Nav from './navigation';
import Footer from './navigation/footer';

const Layout = ({ children, bg = 'white', hideNav, hideFooter }) => {
  return (
    <>
      <Block flex="1 0 auto">
        {!hideNav && <Nav />}
        <Block height="100%" bg={bg} py={40}>
          {children}
        </Block>
      </Block>
      {!hideFooter && <Footer />}
    </>
  );
};

export default Layout;
