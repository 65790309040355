import React from 'react';
import { useAuthContext } from '../firebase/context';

const AuthCondition = ({ isLoggedIn: LoggedIn, isLoggedOut: LoggedOut }) => {
  const { user } = useAuthContext();

  if (user && user.id) {
    return <LoggedIn user={user} />;
  }

  if (LoggedOut) {
    return <LoggedOut />;
  }

  return null;
};

export default AuthCondition;
