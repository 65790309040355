import { Flex } from '@actovos-consulting-group/ui-core';
import React from 'react';
import NavItems from './nav-items';

const DesktopNav = ({ logout }) => {
  return (
    <Flex p={2} ml="auto" alignItems="center">
      <NavItems logout={logout} />
    </Flex>
  );
};

export default DesktopNav;
