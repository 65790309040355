import { Button, Text } from '@actovos-consulting-group/ui-core';
import { Link } from 'gatsby';
import mixpanel from 'mixpanel-browser';
import React from 'react';
import styled from 'styled-components';
import AuthCondition from '../auth-condition';

export const NavItem = styled(Text)`
  text-decoration: none;
  margin-left: 20px;
  color: ${p => p.theme.colors.textColor};
`;

const NavItems = ({ logout }) => {
  return (
    <>
      <NavItem to="/products" as={Link}>
        All Products
      </NavItem>
      <Button
        as={Link}
        to="/submit"
        onClick={() => mixpanel.track('Click Submit Product')}
        style={{ textDecoration: 'none', marginLeft: 20 }}
      >
        Submit a Product
      </Button>
      <AuthCondition
        isLoggedIn={({ user }) => (
          <>
            <NavItem to={`/user/${user.username}`} as={Link}>
              {user.name || user.email}
            </NavItem>
            <Button onClick={logout} variant="transparent">
              Logout
            </Button>
          </>
        )}
        isLoggedOut={() => (
          <NavItem to="/app/login" as={Link}>
            Login
          </NavItem>
        )}
      />
    </>
  );
};

export default NavItems;
