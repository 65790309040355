import PropTypes from 'prop-types';
import React from 'react';
import SVG from './svg';

const Ship = ({ color, width }) => (
  <div style={{ width }}>
    <SVG
      color={color}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 500 500"
      style={{ enableBackground: 'new 0 0 500 500' }}
    >
      <path
        d="M441,75.3c-46-31-113.6-39.8-191.4-38.9c-77.8-1-145.4,7.9-191.4,38.9c-24.6,16.6-30.3,42.4-30.3,63.3
	c0,29.9,8.5,51.4,26.1,62.7c4,2.5,11.8,3.7,8.3,9.1c-5.5,8.4-13.3,19.7-12.5,57.7c0.6,30.1-0.3-13.1,3.6,179.8
	c0.4,9.2,8.3,16.7,17.5,16.7h357.3c9.2,0,17.1-7.5,17.5-16.7c3.9-192.9,3-149.7,3.6-179.8c0.8-38-7-49.3-12.5-57.7
	c-3.5-5.3,4.3-6.5,8.3-9.1c17.6-11.3,26.1-32.8,26.1-62.7C471.3,117.7,465.6,91.8,441,75.3L441,75.3z M279.3,330c-1,0-2-0.1-3.1-0.3
	L203,316c-9-1.7-15-10.5-13.3-19.5l13.8-73.2c1.5-8,8.6-13.6,16.4-13.6c1,0,2,0.1,3.1,0.3l73.2,13.8c9,1.7,15,10.5,13.3,19.5
	l-13.8,73.2C294.2,324.4,287.2,330,279.3,330L279.3,330z"
      />
    </SVG>
  </div>
);

Ship.propTypes = {
  color: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

export default Ship;
