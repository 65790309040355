import { Block, Clickable, Flex } from '@actovos-consulting-group/ui-core';
import { CgClose } from '@react-icons/all-files/cg/CgClose';
import { CgMenuGridR } from '@react-icons/all-files/cg/CgMenuGridR';
import React, { useEffect } from 'react';
import { useLocation, useToggle } from 'react-use';
import styled, { useTheme } from 'styled-components';
import NavItems, { NavItem } from './nav-items';

const MobileNavWrapper = styled(Flex)`
  ${NavItem} {
    font-size: 40px;
  }

  a {
    font-size: 20px;
    padding: 30px;
    margin-left: 0 !important;
  }
`;

const CloseButton = styled(Clickable)`
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 100;
`;

const MobileNav = ({ logout }) => {
  const theme = useTheme();
  const [on, toggle] = useToggle();
  const location = useLocation();

  useEffect(() => {
    toggle(false);
  }, [location]);

  return (
    <>
      <Flex ml="auto">
        <Clickable onClick={toggle}>
          <CgMenuGridR color={theme.colors.primary} size={50} />
        </Clickable>
      </Flex>
      {on && (
        <Block
          zIndex={99}
          height="100vh"
          width="100vw"
          bg="white"
          position="fixed"
          top={0}
          left={0}
        >
          <CloseButton onClick={toggle}>
            <CgClose size={50} color={theme.colors.primary} />
          </CloseButton>
          <Flex height="100%" alignItems="center" justifyContent="center">
            <MobileNavWrapper p={20} alignItems="center" flexDirection="column">
              <NavItems logout={logout} />
            </MobileNavWrapper>
          </Flex>
        </Block>
      )}
    </>
  );
};

export default MobileNav;
