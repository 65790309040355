import { Block, Container } from '@actovos-consulting-group/ui-core';
import React from 'react';
import DisableSSR from '../components/disable-ssr';
import Layout from '../components/layout';
import Logo from '../components/logo';

const Pricing = React.lazy(() =>
  import('../components/scenes/submit/components/pricing'),
);

const CheckoutPage = () => {
  return (
    <Layout hideNav hideFooter>
      <Container>
        <Block my={40} style={{ textAlign: 'center' }}>
          <Logo />
        </Block>
        <DisableSSR>
          <Pricing />
        </DisableSSR>
      </Container>
    </Layout>
  );
};

export default CheckoutPage;
