import {
  Block,
  Container,
  Flex,
  Toaster,
} from '@actovos-consulting-group/ui-core';
import firebase from 'firebase/app';
import { Link } from 'gatsby';
import mixpanel from 'mixpanel-browser';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import Logo from '../logo';
import DesktopNav from './desktop';
import MobileNav from './mobile';

const NavContainer = styled(Block)`
  border-bottom: 1px solid #e5e5e5;
`;

const Nav = () => {
  const matches = useMediaQuery({ query: '(max-width: 800px)' });

  const handleLogout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        mixpanel.track('Logout');
      })
      .catch(() => {
        Toaster.alert({ value: 'An error has occured!', variant: 'error' });
      });
  };

  return (
    <NavContainer py={10}>
      <Container>
        <Flex alignItems="center">
          <Block>
            <Link to="/">
              <Logo />
            </Link>
          </Block>
          {matches ? (
            <MobileNav logout={handleLogout} />
          ) : (
            <DesktopNav logout={handleLogout} />
          )}
        </Flex>
      </Container>
    </NavContainer>
  );
};

export default Nav;
