import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const Logo = () => {
  return (
    <StaticImage
      alt="Roast or Toast"
      src="../images/logo.png"
      placeholder="tracedSVG"
      width={175}
    />
  );
};

export default Logo;
